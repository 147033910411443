var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-0 pt-6",attrs:{"sm":"8","md":"6","lg":"5","xl":"4"}},[_c('div',{staticClass:"px-8"},[_c('v-text-field',{attrs:{"value":_vm.customer.code,"label":_vm.$t('text.code'),"disabled":"","dense":""}}),_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{attrs:{"value":_vm.customer.formatted.name,"label":_vm.$t('text.name'),"disabled":"","dense":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.nameHistory}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-history")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.name-history')))])])],1),_c('v-select',{attrs:{"items":_vm.customerGroups,"label":_vm.$t('text.customer-group'),"loading":_vm.loadingCustomerGroups,"item-text":"code","item-value":"id","dense":""},model:{value:(_vm.customer.customerGroup.id),callback:function ($$v) {_vm.$set(_vm.customer.customerGroup, "id", $$v)},expression:"customer.customerGroup.id"}}),_c('v-text-field',{attrs:{"value":_vm.facebookAccount.pageScopedId,"label":_vm.$t('text.psid'),"disabled":"","dense":""}}),_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{attrs:{"value":_vm.facebookAccount.conversationUrl,"label":_vm.$t('text.conversation-url'),"disabled":"","dense":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.facebookAccount.conversationUrl,"icon":""},on:{"click":_vm.loadConversationUrl}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.generate')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"href":_vm.facebookAccount.conversationUrl,"disabled":!_vm.facebookAccount.conversationUrl,"target":"_blank","icon":""}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.open')))])])],1),_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{attrs:{"value":_vm.walletBalance,"label":_vm.$t('text.wallet-balance'),"disabled":"","dense":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.adjustWallet}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.adjust")))])])],1),_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{attrs:{"value":_vm.expirationDate,"label":_vm.$t('text.expiration-date'),"disabled":"","dense":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.adjustExpirationDate}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.adjust")))])])],1),_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{attrs:{"value":_vm.token,"label":_vm.$t('text.token'),"hint":_vm.tokenHint,"persistent-hint":"","disabled":"","dense":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.token === ' ',"icon":""},on:{"click":_vm.copyToken}},on),[_c('v-icon',[_vm._v("mdi-clipboard")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.copy")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.generateToken}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.refresh")))])])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }