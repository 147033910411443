import AbstractService from "./AbstractService";
import Axios from "axios";

export default class FacebookAccountService extends AbstractService {
    private controller = "facebook-accounts";

	public post(d: any) {
		const url = this.makeUrl(`${this.controller}`);
		return Axios.post<any>(url, d, this.makeConfig());
	}

    public fetch(d: any) {
        const url = this.makeUrl(`${this.controller}/fetch`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
}