import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerTokenService extends AbstractService {
    private controller = "customer-tokens";

    public generate(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/generate`, q);
        return Axios.post<any>(url, {}, this.makeConfig());
    }
}