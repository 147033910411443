


















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerInfoProperties } from "./CustomerInfoView.vue";
import { CustomerWalletDialogProperties } from "./CustomerWalletDialogView.vue";
import { CustomerExpirationDialogProperties } from "./CustomerExpirationDialogView.vue";
import { CustomerNameHistoryDialogProperties } from "./CustomerNameHistoryDialogView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import DateUtil from "@/utilities/DateUtil";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerTokenService from "@/services/CustomerTokenService";
import CustomerGroupService from "@/services/CustomerGroupService";
import FacebookAccountService from "@/services/FacebookAccountService";

@Component({
	data: () => ({
		token: "",
        expiresAt: null,
        customerGroups: [],
        loadingCustomerGroups: false,
	})
})
export default class CustomerMainView extends Vue {
	@Prop() private properties: CustomerInfoProperties;
	@Prop() private alertDialog: AlertDialogProperties;
	@Prop() private snackbar: SnackbarProperties;
    @Prop() private customerWalletDialog: CustomerWalletDialogProperties;
    @Prop() private customerExpirationDialog: CustomerExpirationDialogProperties;
	@Prop() private customerNameHistoryDialog: CustomerNameHistoryDialogProperties;
    private customerTokenService = new CustomerTokenService();
    private customerGroupService = new CustomerGroupService();
	private facebookAccountService = new FacebookAccountService();

    public get customer() {
        return this.properties.customer;
    }

	public get facebookAccount() {
		return this.customer.facebookAccount ?? {};
	}

    public get customerWallet() {
        return this.properties.customerWallet ?? {};
    }

    public get walletBalance() {
        return (this.customerWallet.balance ?? 0).toFixed(2);
    }

    public set walletBalance(v: any) {
        this.customerWallet.balance = v;
    }

    public get tokenHint() {
        if (this.$data.token === "") {
            return null;
        }

        if (!this.$data.expiresAt) {
            return this.$t("hint.token-infinite");
        }
        return this.$t("hint.token-expires", [this.$data.expiresAt]);
    }

    public get expirationDate() {
        const v = this.customer.expirationDate;
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "-";
    }

	public created() {
		this.loadCustomerGroups();
	}

    public async loadCustomerGroups() {
        this.$data.loadingCustomerGroups = true;

        try {
            const r = await this.customerGroupService.get({ hidden: false });
            this.$data.customerGroups = r.data.customerGroups;
        } catch (e) {
            this.$data.customerGroups = [];
        } finally {
            this.$data.loadingCustomerGroups = false;
        }
    }

    public refreshFormat(r: any) {
        const v = r.data.expiresAt;
		const dateTimeFormat = this.properties.dateTimeFormat;
        this.$data.expiresAt = v ? DateUtil.format(new Date(v), dateTimeFormat) : null;
    }

    public async loadConversationUrl() {
		const facebookAccountId = this.customer.facebookAccountId;

		try {
			await this.facebookAccountService.fetch({
				id: facebookAccountId,
				fetchField: "Conversation-Url"
			});

			await this.properties.events.fire('load');
		} catch (e) {
			if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
		}
    }

    public adjustWallet() {
        this.customerWalletDialog.currentBalance = this.walletBalance;
        this.customerWalletDialog.adjustmentAmount = 0;
        this.customerWalletDialog.adjustedBalance = this.walletBalance;
        this.customerWalletDialog.reason = "";
        this.customerWalletDialog.visible = true;
    }

    public adjustExpirationDate() {
        this.customerExpirationDialog.dateFormat = this.properties.dateFormat;
        this.customerExpirationDialog.timeFormat = this.properties.timeFormat;
        this.customerExpirationDialog.expirationDate = this.customer.expirationDate;
        this.customerExpirationDialog.visible = true;
    }

	public nameHistory() {
		this.customerNameHistoryDialog.facebookAccountId = this.customer.facebookAccountId;
		this.customerNameHistoryDialog.visible = true;
	}

    public async generateToken() {
        try {
            const r = await this.customerTokenService.generate({
                current: false,
                customerId: this.customer.id,
            });
            this.refreshFormat(r);
            this.$data.token = r.data.token;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public copyToken() {
        var input = document.createElement("input");
        input.type = "text";
        input.style.top = "0";
        input.style.left = "0";
        input.style.zIndex = "-999";
        input.style.position = "fixed";
        input.value = this.$data.token;
        document.body.append(input);
        input.focus();
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);

        this.snackbar.timeout = 2000;
        this.snackbar.alignment = "bottom-center";
        this.snackbar.message = this.$t("message.clipboard-copied");
        this.snackbar.closable = false;
        this.snackbar.visible = true;
    }
}
